import styled from 'styled-components';

const Copyright = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #464650;
  font-family: 'segoeuii';
  text-align: center;
  margin-top: 30px;
`;

export default Copyright;
